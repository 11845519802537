import React from 'react';
import PageContentContainer from 'src/components/PageContentContainer';
import StickyPageHeader from 'src/components/StickyPageHeader';
import './styles.scss';
/* eslint-disable */
const Version: React.VoidFunctionComponent = ({
}) => {

  return (
    <PageContentContainer>
      <StickyPageHeader>
        <br/>
        <h3>Branch: qa/1.7.1</h3><br />
        <h3>Commit: ecd2de22</h3><br />
        <h3>Build Date: 2023-07-06T18:15:27Z</h3>
      </StickyPageHeader>
    </PageContentContainer>
  );
};

export default (Version);